@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.page-not-found {
  padding: 20px;
  padding-bottom: 0;
  height: calc(100vh - 50px);
  display: grid;
  grid-template-rows: auto min-content;

  @include mq('desktop') {
    height: 100vh;
    max-width: $desktop-container;
    padding: 0;
    padding-left: 70px;
    margin: auto;
    grid-template-columns: 450px auto;
  }

  &__actions {
    display: grid;
    width: 210px;
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    grid-row-gap: 10px;
    letter-spacing: -1px;
    z-index: 1;

    svg {
      width: 87px;
      height: 46px;
      margin-bottom: 20px;
    }

    @include mq('tablet') {
      grid-row-gap: 25px;
      width: 450px;

      svg {
        margin-top: 50px;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(40);
    line-height: 40px;
    color: $text-color-super-darken;

    @include mq('tablet') {
      font-size: rem(54);
      line-height: 56px;
    }
  }

  &__subtitle {
    font-size: rem(18);
    line-height: 22px;
    font-weight: 300;
    color: $text-color-super-darken;

    @include mq('tablet') {
      font-size: rem(26);
      line-height: 35px;
    }
  }

  &__button {
    margin: 0;
    width: 180px;
    height: 50px;

    .btn__label {
      letter-spacing: -1px;
      font-weight: 500;
    }

    @include mq('tablet') {
      width: 224px;
      height: 60px;

      .btn__label {
        font-size: rem(20);
        line-height: em(17);
      }
    }
  }

  &__illustration-wrapper {
    justify-self: end;
  }

  &__illustration {
    position: absolute;
    top: 212px;
    width: auto;
    max-width: 100vw;
    height: calc(100vh - 212px);
    right: -50px;

    @include mq('tablet') {
      top: 326px;
      height: calc(100vh - 326px);
    }

    @include mq('desktop') {
      right: initial;
      max-height: 800px;
      position: relative;
      top: 0;
      height: 120vh;
    }
  }
}
